import React, { useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classes from './profil.module.css';
import { useNavigate } from "react-router-dom";
import useHttp from '../../hooks/ues-http';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { logOut } from '../../store/Auth';
import useApiHttp from '../../hooks/ues-http';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

export default function CustomizedMenus() {
    const { isLoading, error, success, sendRequest: LogOutRequest } = useApiHttp();
    const navigate = useNavigate();

    const logOutSwal = () => {
        LogOutRequest({
            url: `http://13.200.0.24/api/api/logout`,
            method: 'POST',
        }, (data) => {
            console.log("login", data);
        });

        handleClose();
        navigate("/");
        logOut();

    };

    const userName = localStorage.getItem('username');
    const userMail = localStorage.getItem('email');

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div className={classes.profile} style={{ cursor: "pointer" }}>
                <Stack direction="row" spacing={2} onClick={handleClick}>
                    <Avatar sx={{ width: 32, height: 32 }}></Avatar>
                </Stack>
                <div className={classes.profile_name}>
                    <KeyboardArrowDownIcon sx={{ color: "#635d5d" }} onClick={handleClick} />
                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem className='profile_list' onClick={logOutSwal}>
                            Logout
                        </MenuItem>
                    </StyledMenu>
                </div>
            </div>
        </ClickAwayListener>
    );
}
