import React, { useEffect } from 'react';
import { Outlet, Navigate } from "react-router-dom";
import Header from '../features/Header/Header';
import classes from '../Layout/MainLayout.module.css';
import { isAuthenticated } from '../store/Auth';
import Sidebar from '../features/Sidebar/SideBar';

export const MainLayout = () => {
    useEffect(() => {
        if (!isAuthenticated()) {
            return <Navigate to="/home" replace />;
        }
    }, []);

    if (!isAuthenticated()) {
        return <Navigate to="/home" replace />;
    }

    return (
        <div>
            <Header />
            <main className={classes.main_layout}>
                <Sidebar />
                <div style={{ marginLeft: "9rem", paddingTop: "1rem", }}>
                    <Outlet />
                </div>
            </main>
        </div>
    );
}

export default MainLayout;
