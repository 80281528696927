// import React from 'react'
// import TextField from "@mui/material/TextField";

// const TextField = (props) => {
//     return (
//         <>
//             <TextField {...props} />
//         </>
//     )
// }

// export default TextField

import React from 'react';
import MuiTextField from "@mui/material/TextField"; // Renamed import to avoid conflict

const CustomTextField = (props) => {
    return (
        <>
            <MuiTextField {...props} />
        </>
    );
}

export default CustomTextField;
