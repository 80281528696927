export const zoom_data = [
    { value: 0.3 },
    { value: 0.5 },
    { value: 0.7 },
    { value: 1.0 },
    { value: 1.2 },
    { value: 1.5 },
    { value: 1.8 },
    { value: 2.0 },
    { value: 2.5 },
];