
import React from 'react'
import ProfileMenu from '../../components/ProfileMenu/ProfileMenu'
import classes from './Header.module.css'
import Img from '../../components/Images/Img'
import logo from '../../assets/Tanishq Logo.png'
const Header = () => {

    return (
        <div className={classes.header}>
            <nav className={classes.nav}>
                <Img src={logo} alt="logo" className={classes.logo_img} />
                <ProfileMenu />
            </nav>
        </div>


    )
}

export default Header