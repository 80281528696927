import React, { useState, useEffect } from "react";
import classes from "./UploadImage.module.css";
import { PiUploadSimpleThin } from "react-icons/pi";

export default function UploadImage(props) {
    const { setFileTypeError, setErrorFileType, setFileUploadComplied,fileUploadComplied, onChange } = props;
    const [selectedImage, setSelectedImage] = useState(null);
    useEffect(()=>{
        if(fileUploadComplied === null){
            setSelectedImage(null)
        }
    },[fileUploadComplied])

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Validate file type
            const allowedTypes = ["image/jpg", "image/jpeg"];
            if (!allowedTypes.includes(file.type)) {
                setErrorFileType("Invalid file type!");
                setSelectedImage(null);
                return;
            }
            setErrorFileType("");

            setFileUploadComplied(file);

            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target.result);
                // setFileUploadComplied(e.target.result);
            };
            reader.readAsDataURL(file);
        }

        onChange && onChange();
    };

    return (
        <div className={classes["img-upload__box"]}>
            <input
                type="file"
                id="upload-img"
                accept=".jpg,.jpeg"
                hidden
                onChange={handleImageChange}
            />
            <label htmlFor="upload-img">
                {selectedImage ? (
                    <img
                        src={selectedImage}
                        alt="Selected Image"
                        className={classes["uploaded-image"]}
                    />
                ) : (
                    <PiUploadSimpleThin style={{ fontSize: "42px", color: "#a1a1a1" }} />
                )}
            </label>
        </div>
    );
}
