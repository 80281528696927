import React from 'react';
import classes from './Sidebar.module.css';
import { NavLink } from 'react-router-dom';
import { MdVerticalSplit } from "react-icons/md";
import { ImUpload } from "react-icons/im";

const Sidebar = () => {
    return (
        <div className={classes.sidebar}>
            {/* <h2>Sidebar</h2> */}
            <ul>
                <li><NavLink to='/home' className={({ isActive }) =>
                    isActive ? classes.active : ""
                } end><MdVerticalSplit /> Categories</NavLink></li>
                <li><NavLink to="/home/upload" className={({ isActive }) =>
                    isActive ? classes.active : ""
                }><ImUpload />Upload</NavLink></li>
            </ul>
        </div>
    );
};

export default Sidebar;
