import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate } from "react-router-dom";
import useApiHttp from '../../hooks/ues-http';
import { isAuthenticated } from '../../store/Auth';
import classes from './Login.module.css';
import Img from '../../components/Images/Img';
import loginBaner from '../../assets/Login Bg@2x.png';
import logo from '../../assets/Tanishq Logo.png';

function Login() {
    const navigate = useNavigate();

    const [username, setName] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const { isLoading, success, error, sendRequest } = useApiHttp();

    useEffect(() => {
        if (isAuthenticated()) {
            navigate("/home");
        } else {
            navigate("/");
        }
    }, [navigate]);


    useEffect(() => {
        if (success) {
            // navigate("/home");
            setName("");
            setPassword("");
        } else if (error) {
            setErrorMessage('Invalid credentials. Please try again.');
        }
    }, [success, error, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!username || !password) {
            setErrorMessage('Please enter both username and password.');
            return;
        }
        sendRequest({
            url: `http://13.200.0.24/api/api/admin_login`,
            method: 'POST',
            body: {
                username: username,
                password: password,
            },
        }, (data) => {
            console.log("login", data);
            if (data?.data?.token) {
                localStorage.setItem("username", username);
                localStorage.setItem("authToken", data.data.token);
                navigate("/home");
            } else {
                setErrorMessage('Login failed. Please try again.');
            }
        });
    };

    return (
        <div className={classes.login}>
            <div className={classes.left_side}>
                <Img src={loginBaner} alt="loginBaner" className={classes.left_side_img} />
            </div>
            <div className={classes.right_side}>
                <div className={classes["login-box"]}>
                    <Img src={logo} alt="loginBaner" className={classes.logo} />
                    <h1>Welcome Back</h1>
                    <p>Enter your email and password to access your account</p>
                    {errorMessage && <p className={classes.error}>{errorMessage}</p>}
                    <form onSubmit={handleSubmit} className={classes["login-form"]}>
                        <label>
                            Username
                        </label>
                        <input
                            type="text"
                            value={username}
                            onChange={e => setName(e.target.value)}
                            className={classes["input-field"]}
                        />
                        <label>
                            Password
                        </label>
                        <input
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            className={classes["input-field"]}
                        />
                        <button type="submit" className={classes["login-button"]}>Sign in</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
