import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import classes from './Home.module.css';
import Img from '../../components/Images/Img';
import folder from '../../assets/Folder.png';
import useApiHttp from '../../hooks/ues-http';
import bungale from '../../assets/Bitmap Copy 2@2x.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Home = () => {
    const { isLoading: categoriesLoading, success: categoriesSuccess, error: categoriesError, sendRequest: categoriesSendRequest } = useApiHttp();
    const { isLoading: banglesLoading, success: banglesSuccess, error: banglesError, sendRequest: banglesSendRequest } = useApiHttp();
    const { isLoading: productImagesLoading, success: productImagesSuccess, error: productImagesError, sendRequest: productImagesSendRequest } = useApiHttp();

    const [color, setColor] = useState("");
    const [tapHudler, setTapHudler] = useState(1);
    const [Categories, setCategories] = useState(true);
    const [bangles, setBangles] = useState(false);
    const [productImages, setProductImages] = useState(false);

    const [categoryName, setCategoryName] = useState("");
    const [folderName, setFolderName] = useState("");

    const [categoriesData, setCategoriesData] = useState([]);
    const [banglesData, setBanglesData] = useState([]);
    const [productImagesData, setProductImagesData] = useState([]);

    const [folderNameNavicater, setFolderNameNavicater] = useState("");

    const [selectedImage, setSelectedImage] = useState(null);


    console.log("productImagesData", productImagesData);
    const userName = localStorage.getItem('username');

    const navigatHudler = (data) => {
        if (data === "Categories") {
            setCategories(true);
            setBangles(false);
            setProductImages(false);
        } else if (data === "Bangles") {
            setCategories(false);
            setBangles(true);
            setProductImages(false);
        } else if (data === "ProductImages") {
            setCategories(false);
            setBangles(false);
            setProductImages(true);
        }
    };

    const categoriesSendRequestData = () => {
        categoriesSendRequest({
            url: `http://13.200.0.24/api/api/categories`,
            method: 'POST',
            body: {
                location: userName,
            },
        }, (data) => {// Log the API response
            if (data && data.data) {
                setCategoriesData(data.data);
            } else {
                setCategoriesData([]); // Set an empty array if data structure is unexpected
            }
        });
    };
    const banglesSendRequestData = (name) => {
        setFolderNameNavicater(name)
        setCategories(false);
        setBangles(true);
        setProductImages(false);
        setCategoryName(name)
        banglesSendRequest({
            url: `http://13.200.0.24/api/api/folder`,
            method: 'POST',
            body: {
                location: userName,
                category_name: name,
            },
        }, (data) => {// Log the API response
            if (data && data.data) {
                setBanglesData(data.data);
            } else {
                setBanglesData([]); // Set an empty array if data structure is unexpected
            }
        });
    };
    const productImagesSendRequestData = (name) => {
        setCategories(false);
        setBangles(false);
        setProductImages(true);
        setFolderName(name)
        productImagesSendRequest({
            url: `http://13.200.0.24/api/api/images`,
            method: 'POST',
            body: {
                location: userName,
                category_name: categoryName,
                folder_name: name
            },
        }, (data) => {// Log the API response
            if (data && data.data) {
                setProductImagesData(data.data);
            } else {
                setProductImagesData([]); // Set an empty array if data structure is unexpected
            }
        });
    };

    useEffect(() => {
        if (Categories) {
            categoriesSendRequestData();
        }

    }, [Categories, bangles, productImages]);

    const closeModal = () => {
        setSelectedImage(null);
    };
    const handleImageClick = (image) => {
        setSelectedImage(image);
    };


    return (
        <>
            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" onClick={() => navigatHudler("Categories")} sx={{ cursor: "pointer" }}>
                        <Typography color={`${Categories ? "#a23032" : ""}`}>Categories</Typography>
                    </Link>
                    {banglesData.length !== 0 && <Link underline="hover" color="inherit" onClick={() => navigatHudler("Bangles")} sx={{ cursor: "pointer" }}>
                        <Typography color={`${bangles ? "#a23032" : ""}`}>{folderNameNavicater}</Typography>
                    </Link>}
                    {(productImagesData.length !== 0 && folderNameNavicater === folderNameNavicater) && <Typography color={`${productImages ? "#a23032" : ""}`} sx={{ cursor: "pointer" }} onClick={() => navigatHudler("ProductImages")}>Product Images</Typography>}
                </Breadcrumbs>
            </div>
            <div className={classes.container}>
                {Categories && categoriesData && Array.isArray(categoriesData) &&
                    <div className={classes["image-grid"]}>
                        {categoriesData.map((item, index) => (
                            <div className={classes.folder} key={index} onClick={() => banglesSendRequestData(item?.category_name)}>
                                <div className={classes.folder_img}>
                                    <Img src={folder} alt="folder" />
                                </div>
                                <p style={{ margin: 0, textAlign: "center", textTransform: "uppercase", wordWrap: "break-word", }}>
                                    {item?.category_name}
                                </p>
                            </div>
                        ))}
                    </div>
                }
                {bangles && banglesData && Array.isArray(banglesData) &&

                    <div className={classes["image-grid"]}>
                        {banglesData.map((item, index) => (
                            <div className={classes.folder} key={index} onClick={() => productImagesSendRequestData(item?.folder_name)}>
                                <div className={classes.folder_img}>
                                    <Img src={folder} alt="folder" />
                                </div>
                                <p style={{ margin: 0, textAlign: "center", textTransform: "uppercase", wordWrap: "break-word", }}>
                                    {item?.folder_name}
                                </p>
                            </div>
                        ))}
                    </div>
                }
                {productImages && productImagesData && Array.isArray(productImagesData) &&
                    <div className={classes["image-grid"]}>
                        {productImagesData.map((item, index) => (
                            <div className={classes.folder} key={index} onClick={() => handleImageClick(`${item?.image_url}`)}>
                                <div className={classes.img_box}>
                                    <LazyLoadImage src={item?.image_url} alt="bungale" effect="blur" placeholderSrc={item?.image_url}/>
                                </div>
                                <p style={{ margin: 0, textAlign: "center", textTransform: "uppercase", wordWrap: "break-word", }}>{item?.category_name}</p>
                            </div>
                        ))}

                    </div>
                }
                {selectedImage && (
                    <div className={classes.modal} onClick={closeModal}>
                        <span className={classes.close}>&times;</span>
                        <img className={classes["modal-content"]} src={selectedImage} alt={"imge"} />

                    </div>
                )}
            </div>
        </>
    );
};

export default Home;
