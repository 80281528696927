import React from 'react'

const Img = (props) => {
    return (
        <>
            <img src={props.src} alt={props.alt} {...props} />
        </>
    )
}

export default Img