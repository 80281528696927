import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from '../pages/Login/Login.jsx';
import Home from '../pages/Home/Home.jsx';
import { MainLayout } from '../Layout/MainLayout.jsx';
import Categories from '../features/Home/Categories/Categories.jsx';
import Bangles from '../features/Home/Bangles/Bangles.jsx';
import ProductImages from '../features/Home/ProductImages/ProductImages.jsx';
import UploadPage from '../pages/Upload/UploadPage.jsx';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Login />,
    },
    {
        path: '/home',
        element: <MainLayout />,
        children: [
            { index: true, element: <Home /> },  // Fixed `index` route
            {
                path: "categories",
                element: <Categories />,
                // loader: teamLoader,
            },
            {
                path: "bangles",
                element: <Bangles />,
                // loader: teamLoader,
            },
            {
                path: "productImages",
                element: <ProductImages />,
                // loader: teamLoader,
            },
            {
                path: 'upload',
                element: <UploadPage />,
            },
        ],
    },

    // Uncomment and define ErrorPage if necessary
    // {
    //     path: '*',
    //     element: <ErrorPage />,
    // }
]);

export default function Admin() {
    return <RouterProvider router={router} />;
}
