import React, { useState,useEffect } from 'react'
import classes from "./UploadPage.module.css"
import TextField from '../../components/TextField/TextField'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {
    Typography,
    Box,
    FormControl,
    Button,
    Divider,
    IconButton,
    Slider,
    Stack,
} from "@mui/material";
import { Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material';

import UploadImage from '../../components/UploadImge/UploadImage';
import SelectSmall from '../../components/Slecter/SelectInput';
import { zoom_data } from '../../store/Dummy_data';
import useApiHttp from '../../hooks/ues-http';

const UploadPage = () => {

    const [imageUploadAlert, setImageUploadAlert] = useState(false);
    const [fileTypeError, setFileTypeError] = useState('');
    const [errorFileType, setErrorFileType] = useState('');
    const [fileUploadComplied, setFileUploadComplied] = useState(null);
    const [categoriesValue, setCategoriesValue] = useState("");
    const [minExposure, setMinExposure] = useState(0);
    const [maxExposure, setMaxExposure] = useState(0);

    console.log("fileUploadComplied",fileUploadComplied);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(null);
    const [uploadError, setUploadError] = useState(null);

    const [error, setError] = useState({
        categories_name: false,
        minExposure: false,
        maxExposure: false,
    })

    console.log("categoriesValue", categoriesValue);

    const [zoomPosition, setZoomPosition] = useState({
        zoom_position1: "",
        zoom_position2: "",
    });


    const handleChangeSlecter = (event) => {
        // setAge(event.target.value);
        const { name, value } = event.target;
        setZoomPosition((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };


    const handleMinExposureChange = (event, newValue) => {
        setMinExposure(newValue);
    };

    const handleMaxExposureChange = (event, newValue) => {
        setMaxExposure(newValue);
    };
   
       useEffect(() => {
       if (uploadSuccess !== null) {
           toast.success(uploadSuccess, {
               position: "top-center",
           })
  
       }
       if (uploadError !== null) {
           toast.error(uploadError, {
               position: "top-center",
           });
       }
   }, [uploadSuccess, uploadError]);



    const handleSubmit = async (event) => {
        event.preventDefault();


        let formIsValid = true;

        if (fileUploadComplied === null) {
            setImageUploadAlert(true);
            formIsValid = false;
        } else {
            setImageUploadAlert(false);
        }

        if (categoriesValue === "") {
            setError((prev) => ({ ...prev, categories_name: true }));
            formIsValid = false;
        } else {
            setError((prev) => ({ ...prev, categories_name: false }));
        }

        if (minExposure === 0) {
            setError((prev) => ({ ...prev, minExposure: true }));
            formIsValid = false;
        } else {
            setError((prev) => ({ ...prev, minExposure: false }));
        }

        if (maxExposure === 0) {
            setError((prev) => ({ ...prev, maxExposure: true }));
            formIsValid = false;
        } else {
            setError((prev) => ({ ...prev, maxExposure: false }));
        }

        if (formIsValid) {
  
            const formData = new FormData();
            formData.append("category_name", categoriesValue);
            formData.append("zoom_position1", zoomPosition?.zoom_position1 );
            formData.append("zoom_position2", zoomPosition?.zoom_position2 );
            formData.append("minimum_exposure", minExposure);
            formData.append("maximum_exposure", maxExposure);
            formData.append("image", fileUploadComplied);// Append the file to FormData
            formData.append("distance", "");

            const AdminbearerToken = localStorage.getItem('authToken');
            setIsLoading(true);
            setUploadSuccess(null);
            setUploadError(null);

            try {
                const response = await axios.post(
                    `http://13.200.0.24/api/api/upload_category`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${AdminbearerToken}`,
                        },
                    }
                );
                setUploadSuccess(response.data.message);
                setError({
                    categories_name: false,
                    minExposure: false,
                    maxExposure: false,
                });
                setCategoriesValue("");
                setFileUploadComplied(null);
                setMinExposure(0);
                setMaxExposure(0);
                setZoomPosition({
                    zoom_position1: "",
                    zoom_position2: "",
                });
            } catch (error) {
                setUploadError(error.response?.data?.message || 'Something went wrong!');
            } finally {
                setIsLoading(false);
            }
        
        }
    };


    return (
        <div className="">
            <form
            enctype="multipart/form-data" method="post" name="fileinfo" id="fileinfo"
                action=""
                onSubmit={handleSubmit}
                className={`${classes["form-enroll"]} form__personal-details`}
            >
                <Box className={classes.form_field__box} mt={6}>
                    <div>
                        <UploadImage
                            name="profile_image_file"
                            onChange={() => setImageUploadAlert(false)}
                            setFileTypeError={setFileTypeError}
                            setErrorFileType={setErrorFileType}
                            setFileUploadComplied={setFileUploadComplied}
                            fileUploadComplied={fileUploadComplied}
                        />
                        <Typography
                            variant="caption"
                            component="p"
                            color={errorFileType !== "" ? errorFileType : imageUploadAlert
                                ? "#eb0000"
                                : "#3f3f3f"}
                            fontSize="14px"
                            mt={1.5}
                        >
                            {errorFileType !== "" ? errorFileType : imageUploadAlert
                                ? "Profile image is required"
                                : " Add category image*"}
                        </Typography>
                    </div>


                </Box>
                <Box className={classes.form_field__box} mt={6}>
                    <div className={classes.relative}>
                        {error.categories_name && <p className={`${classes.error} ${classes.top}`}>Please Enter your Category Name</p>}
                        <TextField
                            className={`${classes.input_field}`}
                            id="categories-name"
                            name="category_name"
                            label="Category Name"
                            placeholder="Enter your Category Name"
                            value={categoriesValue}
                            onChange={(e) => setCategoriesValue(e.target.value)}

                        />
                    </div>


                    <div>
                        <FormLabel component="legend" sx={{ marginBottom: "0.5rem" }}>Zoom Position</FormLabel>
                        <div className={classes.slecterValue}>
                            <SelectSmall
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={zoomPosition.zoom_position1}
                                name={"zoom_position1"}
                                label="position1"
                                onChange={handleChangeSlecter}
                                data={zoom_data}
                                minWidth={120}

                            />
                            <SelectSmall
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={zoomPosition.zoom_position2}
                                name={"zoom_position2"}
                                label="position2"
                                onChange={handleChangeSlecter}
                                data={zoom_data}
                                minWidth={120}

                            />
                        </div>
                    </div>

                    {/* <div>
                        <EnhancedCheckboxComponent checkedItems={checkedItems} handleChange={handleChange} handleSelectAll={handleSelectAll} />
                    </div> */}

                </Box>
                <Box className={classes.form_field__box} mt={6}>
                    <div>
                        <Typography color={"#3f3f3f"} variant="subtitle1" mb={4}>
                            Minimum Exposure
                        </Typography>
                        <div className={classes.relative}>
                            {error.minExposure && <p className={`${classes.error} ${classes.bottom}`}> Please select your minexposure value</p>}
                            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                <span>-5</span>
                                <Slider
                                    // size="small"
                                    defaultValue={minExposure}
                                    value={minExposure}
                                    aria-label="experience"
                                    min={-5}
                                    max={5}
                                    valueLabelDisplay="on"
                                    onChange={handleMinExposureChange}
                                    onChangeCommitted={handleMinExposureChange}
                                    required
                                />
                                <span>5</span>
                            </Stack>
                        </div>

                    </div>

                    <div>
                        <Typography color={"#3f3f3f"} variant="subtitle1" mb={4}>
                            Maximum Exposure
                        </Typography>
                        <div className={classes.relative}>
                            {error.maxExposure && <p className={`${classes.error} ${classes.bottom}`}>Please enter your maxexposure value</p>}
                            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                <span>-5</span>
                                <Slider
                                    // size="small"
                                    defaultValue={maxExposure}
                                    value={maxExposure}
                                    aria-label="experience"
                                    min={-5}
                                    max={5}
                                    valueLabelDisplay="on"
                                    onChange={handleMaxExposureChange}
                                    onChangeCommitted={handleMaxExposureChange}
                                    required
                                />
                                <span>5</span>
                            </Stack>
                        </div>


                    </div>

                </Box>

                <Box textAlign="right" mt={4}>
                    <Button variant="contained" sx={{ backgroundColor: '#a23032', '&:hover': { backgroundColor: '#7b2224' } }} type="submit">
                        Submit
                    </Button>
                </Box>

            </form>

            <ToastContainer/>
        </div>
    )
}

export default UploadPage