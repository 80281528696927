import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectSmall(props) {


  return (
    <FormControl sx={{ m: props?.m, minWidth: props?.minWidth }} size="small">
      {props?.label && <InputLabel id="demo-select-small-label">{props?.label}</InputLabel>}

      <Select
        {...props}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {props?.data?.map((item, index) => (
          <MenuItem key={index} value={item?.value}>{item?.value}</MenuItem>
        ))}

      </Select>
    </FormControl>
  );
}